import {createAppRouter} from "@packages/router";
import {routes} from "@/router/routes";
import {NavigationGuardNext, RouteLocationNormalized} from "vue-router";
import {useAuthStore} from "@/stores/auth";

const router = createAppRouter(routes)

router.beforeResolve(
    (
        to: RouteLocationNormalized,
        from: RouteLocationNormalized,
        next: NavigationGuardNext
    ) => {
        const auth = useAuthStore();

        if (to["meta"] && to.meta.auth && !auth.getToken) {
            return next({ name: "login" });
        }

        return next();
    }
);

export default router