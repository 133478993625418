import Vue3EasyDataTable from "vue3-easy-data-table";
import "vue3-easy-data-table/dist/style.css";
import LucideIcons from "./icons/index.js";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "./modal/index.js";
import {
  Dropdown,
  DropdownContent,
  DropdownDivider,
  DropdownFooter,
  DropdownHeader,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "./dropdown/index.js";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "./tab/index.js";
import {
  Accordion,
  AccordionGroup,
  AccordionItem,
  AccordionPanel,
} from "./accordion/index.js";
import { Alert } from "./alert/index.js";
import {
  Highlight,
  Preview,
  PreviewComponent,
  Source,
} from "./preview-component/index.js";
import Litepicker from "./litepicker/Main.vue";
import LoadingIcon from "./loading-icon/Main.vue";

export default (app) => {
  app.component("EasyDataTable", Vue3EasyDataTable);
  app.component("Litepicker", Litepicker);
  app.component("LoadingIcon", LoadingIcon);
  app.component("Modal", Modal);
  app.component("ModalHeader", ModalHeader);
  app.component("ModalBody", ModalBody);
  app.component("ModalFooter", ModalFooter);
  app.component("Dropdown", Dropdown);
  app.component("DropdownToggle", DropdownToggle);
  app.component("DropdownMenu", DropdownMenu);
  app.component("DropdownContent", DropdownContent);
  app.component("DropdownItem", DropdownItem);
  app.component("DropdownHeader", DropdownHeader);
  app.component("DropdownFooter", DropdownFooter);
  app.component("DropdownDivider", DropdownDivider);
  app.component("TabGroup", TabGroup);
  app.component("TabList", TabList);
  app.component("Tab", Tab);
  app.component("TabPanels", TabPanels);
  app.component("TabPanel", TabPanel);
  app.component("AccordionGroup", AccordionGroup);
  app.component("AccordionItem", AccordionItem);
  app.component("Accordion", Accordion);
  app.component("AccordionPanel", AccordionPanel);
  app.component("Alert", Alert);
  app.component("PreviewComponent", PreviewComponent);
  app.component("Preview", Preview);
  app.component("Source", Source);
  app.component("Highlight", Highlight);
  for (const [key, icon] of Object.entries(LucideIcons)) {
    app.component(key, icon);
  }
};
