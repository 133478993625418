import axios, {AxiosInstance, AxiosRequestConfig} from "axios";
import {useInterceptors} from "./interceptors";
import {cloneDeep} from "lodash";

export default class Http {
    protected _axios: AxiosInstance;

    constructor(baseUrl?: string, config?: AxiosRequestConfig) {
        this._axios = this.computeAxios(baseUrl, config)
    }

    get axios(): AxiosInstance {
        return this._axios
    }

    protected set axios(axios: AxiosInstance) {
        this._axios = axios
    }

    protected computeAxios (baseUrl?: string, config?: AxiosRequestConfig): AxiosInstance {
        config = config ?? {}

        const configuration: AxiosRequestConfig = typeof baseUrl === 'string' ? Object.assign(config, {baseURL: baseUrl}) : config

        return useInterceptors(axios.create(configuration))
    }

    useAxios(): AxiosInstance {
        return cloneDeep(this.axios)
    }
}