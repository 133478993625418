//string enum...
export enum GeneralPrefixes {
    Null = '',
    MakeSales = 'make-sales-',
    MakeIncomeFromSales = 'make-income-from-sales-',
    CreateIncomeFromSalesAndPayForIt = 'create-income-from-sales-and-pay-for-it-',
    UpdateIncomeFromSales = 'update-income-from-sales-',
    MakeExpenseToCreate = 'make-expense-to-create-',
    MakeExpenseToUpdate = 'make-expense-to-update-',
    CreateExpenseAndPayForIt = 'create-expense-and-pay-for-it-',
    UpdateExpense = 'update-expense-',
    Calendar = 'calendar-',
    SchedulerCalendar = 'scheduler-calendar-',
    SharedCalendarSettings = 'shared-calendar-settings-',
    UMUpdateCalendar = 'shared-calendar-settings-',
}