import {defineStore} from "pinia";
import http from "../http";
import {ResourceResponse} from "@packages/contracts/resource-response";
import {Company} from "@packages/contracts/company";
import {User} from "@packages/contracts/user";
import {Product} from "@packages/contracts/product";
import {AxiosResponse} from "axios";
import {Bundle} from "@packages/contracts/bundle";

interface CompanySearchResult {
    model_type: string | undefined | null;
    id: number;
    text: string;
}

export const useCompanyStore = defineStore("company", {
    state: (): {
        company: object | undefined;
        performers: User[];
        products: Product[];
        allCompanies: Company[];
        currentCompany: Company | undefined;
        currentCompanyId: number | undefined;
        bundles: Bundle[];
    } => ({
        company: undefined,
        performers: [],
        products: [],
        allCompanies: [],
        currentCompany: ((): Company | undefined => {
            const fromStorage = localStorage.getItem("current_company");

            return fromStorage !== null ? (JSON.parse(fromStorage) ?? undefined) : undefined;
        })(),
        currentCompanyId: undefined,
        bundles: []
    }),
    getters: {
        getPerformers(state): User[] {
            return state.performers;
        },

        getProductByPerformer: (
            state
        ): ((performerId: number) => Product | undefined) => {
            return (performerId: number) => {
                let performer = state.performers.find((q) => q.id === performerId);

                if (performer) {
                    return performer.products![0];
                }
            };
        },

        getProducts(state): Product[] {
            return state.products;
        },

        getPerformerByProduct: (
            state
        ): ((productId: number) => User | undefined) => {
            return (productId: number) => {
                let performer = state.performers.find(
                    (q) => q.products?.filter((a) => a.id == productId).length
                );

                if (performer) {
                    return performer;
                }
            };
        },

        getSelectedCompany: (state): Company | undefined => {
            return state.currentCompany;
        },

        getBundles(state): Bundle[] {
            return state.bundles;
        }
    },
    actions: {
        getPerformerById(id: number): User | null {
            return this.getPerformers.find((i: User) => i.id === id) ?? null;
        },

        getProductById(id: number): Product | null {
            return this.getProducts.find((i: Product) => i.id === id) ?? null;
        },

        setSelectedCompany(company: Company | undefined) {
            this.currentCompany = company;

            localStorage.setItem(
                "current_company",
                JSON.stringify(company ?? null)
            );

            return this;
        },

        async getCompanyInformation() {
            try {
                let {data} = await http.get<ResourceResponse<Company>>("/company");

                if (data.data) {
                    let {id, name, title, performers, products, bundles} = data.data;
                    this.company = {id, name, title};
                    this.performers = performers || [];
                    this.products = products || [];
                    this.bundles = bundles || [];
                }
            } catch {
                this.company = undefined;
                this.performers = [];
                this.products = [];
                this.bundles = []
            }
        },

        async getCompanyAttributes() {

            let {data} = await http.get<any>("/company");

            return data.data.attributes;
        },

        async searchCompanies(query: string = ""): Promise<CompanySearchResult[]> {

            let {data} = await http.get("/company/search", {

                params: {q: query},

            });

            return data;

        },

        async getAllCompanies(): Promise<void> {
            try {
                let {data} = await http.get<
                    ResourceResponse<{ companies: Company[]; current_company: Company }>
                >("/company/my-companies");

                this.allCompanies = data.data.companies;
                this.setSelectedCompany(data.data.current_company)
            } catch {
                this.allCompanies = []
                this.setSelectedCompany(undefined)
            }
        },

        changeCompany(event: any) {
            let comp = this.allCompanies.find(
                (q) => q.id === Number.parseInt(event.target.value)
            );

            if (comp) {
                //changes the selected company
                this.setSelectedCompany(comp);

                //reloads
                window.location.reload();
            }
        },

        async editCompany(companyId: number, data: any): Promise<AxiosResponse> {
            return await http.put("/company/edit/" + companyId, data);
        },

        async setAppointmentSettings(data: any): Promise<AxiosResponse> {
            return await http.put("/company/set-appointment-settings", data);
        },
    },
});
