import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
} from "vue-router";

function createAppRouter (routes: RouteRecordRaw[]) {
  return createRouter({
    //@ToDo: if need be turn import.meta or import.meta.env.VITE_APP_BASE_PATH into an argument. first research how this
    //  should be done
    history: createWebHistory(import.meta.env.VITE_APP_BASE_PATH as string),
    routes: routes,
    scrollBehavior(to, from, savedPosition) {
      return savedPosition || { left: 0, top: 0 };
    },
  });
}

export {
  createAppRouter,
}
