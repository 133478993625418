import Http from "../http/Http";
import {AxiosInstance, AxiosResponse} from "axios";
import {ErrorResponse, SuccessResponse} from "@packages/contracts/success-response";
import {User} from "@packages/contracts/user";
import {EnumTranslation} from "@/stores/enum";
import {Branch} from "@packages/contracts/branch/branch";
import {useCompanyStore} from "@/stores/company";

const baseUrl: string = import.meta.env.VITE_API_BASE_V2 + '/companies/branches'

const http = new Http(baseUrl);

const axios: AxiosInstance = http.axios;

export const detachClient = async (branchId: number, clientId: number): Promise<AxiosResponse> => {
    return await axios.delete('/detach-client', {params: {branch_id: branchId, client_id: clientId}})
}

export const attachUser = async (branchId: number, userId: number): Promise<SuccessResponse<any>> => {
    return await axios.post('/attach-user', {branch_id: branchId, user_id: userId})
}

export const updateBranchsUser = async (userId: number, data: any): Promise<SuccessResponse<User>> => {
    return await axios.put(`/user/${userId}/update-branchs-user`, data)
}

export const getBranchRoles = async (): Promise<SuccessResponse<EnumTranslation>> => {
    return await axios.get('/get-branch-roles')
}

export const detachUser = async (branchId: number, userId: number, branchUserId?: number): Promise<SuccessResponse<any>> => {
    return await axios.delete('/detach-user', {params: {branch_id: branchId, user_id: userId, id: branchUserId ?? ''}})
}

export const updateBranch = async (data: any): Promise<SuccessResponse<Branch>> => {
    return await axios.put(`/update-branch`, data)
}

export const getBranch = async (branchId?: number): Promise<SuccessResponse<Branch>> => {

    if(typeof branchId !== 'number') {
        branchId = useCompanyStore().getSelectedCompany?.id;
    }

    return await axios.get(`/get-branch`, {params: {id: branchId}})
}

export const addUserThatCannotLogInToBranch = async (data: any): Promise<SuccessResponse<User>> => {
    return await axios.post('/add-user-tcnli', data)
}

export async function beginProcessToAddUserThatCanLoginToBranch(data: any): Promise<SuccessResponse<0|1>|ErrorResponse> {
    return await axios.post('add-user-tcli/begin', data)
}

export async function acceptAddUserRequest(
    accept: boolean, token: string, set_password: boolean,
    password_inputs: {main: string|null, confirmation: string|null} = {main: null, confirmation: null}
): Promise<SuccessResponse<User|null>|AxiosResponse> {
    return await (new Http(import.meta.env.VITE_API_BASE_V2 + '/companies')).axios
        .put('/add-user-tcli/accept', {accept, token, set_password, password: password_inputs?.main, password_confirmation: password_inputs?.confirmation})
}

export async function verifyPhoneOfNewUserToAddUser(token: string): Promise<SuccessResponse<string>|AxiosResponse> {
    return await (new Http(import.meta.env.VITE_API_BASE_V2 + '/companies')).axios
        .put('/add-user-tcli/verify-phone', {token})
}

export async function verifyPhoneOfExistingUserThatWillLogIn(token: string): Promise<SuccessResponse<string>|AxiosResponse> {
    return await (new Http(import.meta.env.VITE_API_BASE_V2 + '/companies')).axios
        .put('/allow-user-login/verify-phone', {token})
}

export async function allowExistingUserToLogInBeginProcess(user_id: number, email: string): Promise<SuccessResponse<void>|AxiosResponse> {
    return await axios.post('/allow-user-login/begin', {id: user_id, email})
}


export async function allowExistingUserToLogInCompleteProcess(
    token: string, password_inputs: {main: string|null, confirmation: string|null} = {main: null, confirmation: null}
): Promise<SuccessResponse<void>|AxiosResponse> {
    return await (new Http(import.meta.env.VITE_API_BASE_V2 + '/companies')).axios
        .put('/allow-user-login/complete', {
            token, password: password_inputs?.main, password_confirmation: password_inputs?.confirmation
        })
}

export async function manageUsersRelationsWithFirm(data: any): Promise<SuccessResponse<void>|AxiosResponse> {
    return await axios.put('/manage-user-relations', data)
}

export async function removeUserFromBranch(user_id: number, branch_id: number): Promise<SuccessResponse<void>|AxiosResponse> {
    return await axios.put('/remove-user', {user_id, branch_id})
}

export async function updateBranchsUserThatCannotLogIn(userId: number, data: any): Promise<SuccessResponse<User>|AxiosResponse> {
    return await axios.put(`user/${userId}/update-user-tcnli`, data)
}

export async function revokeAddUserRequestAsBranch(id: number): Promise<SuccessResponse<void>|AxiosResponse> {
    return await axios.put('/add-user-request/revoke/' + id)
}