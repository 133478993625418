import { defineStore } from "pinia";
import { User } from "@packages/contracts/user";
import useEmitter from "@packages/composables/useEmitter";
import type {SuccessResponse} from "@packages/contracts/success-response";
import type {NavigationFailure} from "vue-router";

function defineAuthStore (
    logOutRequest: () => Promise<SuccessResponse<boolean>>,
    redirectToLoginPage: () => Promise<void | NavigationFailure | undefined>,
    getUserRequest: () => Promise<SuccessResponse<User>>
) {
    return function () {
        const defStore = defineStore("auth", {
            state: (): { user?: User; token?: string; shouldInitialize: boolean } => {
                //retrieves the items from local storage
                const fromStorage = {
                    user: localStorage.getItem('user'),
                    token: localStorage.getItem('token'),
                }

                return {
                    user: fromStorage.user !== null ? JSON.parse(fromStorage.user) : undefined,
                    token: fromStorage.token !== null ? JSON.parse(fromStorage.token) : undefined,
                    shouldInitialize: true,
                }
            },
            getters: {
                getToken(state): string | undefined {
                    return state.token;
                },

                getUser(state): User | undefined {
                    return state.user;
                },
            },
            actions: {
                setUser(user: User|undefined) {
                    //updates the store's state
                    this.user = user

                    //updates local storage
                    user ? localStorage.setItem('user', JSON.stringify(user))
                        : localStorage.removeItem('user')

                    return this
                },

                setToken(token: string|undefined) {
                    //updates the store's state
                    this.token = token

                    //updates local storage
                    token ? localStorage.setItem('token', JSON.stringify(token))
                        : localStorage.removeItem('token')

                    return this
                },

                /**
                 * The method to update the store's state after logging in.
                 * @param token The bearer token
                 * @param user the authenticated user
                 */
                loggedIn(token: string, user: User) {
                    this.setToken(token).setUser(user)

                    useEmitter().emit('loggedIn')

                    return this
                },

                isLoggedIn(): boolean {
                    return Boolean(this.token) && Boolean(this.user)
                },

                /**
                 * Logs out the current user and returns true if the request is successful. Returns false otherwise.
                 */
                async logout(): Promise<boolean> {
                    try {
                        const response = await logOutRequest()

                        //updates the store state and returns true if the response is a success response
                        if(response.status === 200) {
                            localStorage.removeItem("token");

                            localStorage.removeItem("user");

                            this.$reset()

                            return true
                        }
                    } catch {
                    }

                    //returns false as we did not get a success response
                    return false
                },

                /**
                 * Logs out the current user, and redirects to the login page. Returns true if logged out successfully. Returns
                 * false otherwise. Also returns true if the user was not logged in at the beginning.
                 */
                async logOutAndRedirectToLogIn(): Promise<boolean> {
                    await this.logout()

                    async function redirectToLogIn () {
                        return await redirectToLoginPage()
                    }

                    //if the user is not logged in, redirects to the login page and returns true
                    if(! this.isLoggedIn()) {
                        await redirectToLogIn()

                        return true
                    }

                    //tries logging out
                    const isSuccessful = await this.logout();

                    //redirects to the login page and returns true if logged out successfully
                    if(isSuccessful) {
                        await redirectToLogIn()

                        return true
                    }
                    //returns false if it has failed to log out
                    else {
                        return false
                    }
                },

                /**
                 * Reconciles the state of the store with the backend by making a request to the backend
                 */
                async reconcileWithBackend () {
                    //no need to reconcile with the backend if the user is not set.
                    if (! this.user) {
                        return this
                    }

                    //we will try to get the user by making a request to the backend. if this is not successful (i.e. the response's
                    //status is not 200 or an exception gets thrown) causes an exception, then there must be a problem with
                    // authentication. Hence, unless this is successful, logs out the user.
                    try {
                        const response = await getUserRequest()

                        //if the request has been successful
                        if (response.status === 200) {
                            this.setUser(response.data.data)

                            return this
                        }
                    } catch {
                    }

                    await this.logout()

                    return this
                },

                initialize () {
                    //returns if it should not initialize
                    if(! this.shouldInitialize) {
                        return this
                    }

                    //sets shouldInitialize to false since the following logic should be executed only once.
                    this.shouldInitialize = false

                    this.reconcileWithBackend()

                    return this
                },
            },
        })

        const store = defStore()

        store.initialize()

        return store
    }
}



export {
    defineAuthStore,
}
