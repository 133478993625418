import Echo from "laravel-echo";
import Pusher from "pusher-js/with-encryption";
import type {AxiosInstance} from "axios";

function globallyRegisterWebSocket(axios: AxiosInstance, url: string): void {
  (window as any).Pusher = Pusher;

  (window as any).Echo = new Echo({
    broadcaster: "pusher",
    key: import.meta.env.VITE_ABLY_PUBLIC_KEY,
    wsHost: import.meta.env.VITE_WS_HOST,
    authorizer: (channel: any, options: any) => {
      return {
        authorize: (socketId: any, callback: any) => {
          axios
              .post(url, {
                socket_id: socketId,
                channel_name: channel.name,
              })
              .then((response) => {
                callback(null, response.data);
              })
              .catch((error) => {
                callback(error);
              });
        },
      };
    },
    wsPort: 443,
    disableStats: true,
    encrypted: true,
  });
}

export {
  globallyRegisterWebSocket,
}
