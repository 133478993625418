import {Component, createApp} from "vue";
import "@packages/assets/css/app.css";
import { createPinia } from "pinia";
import utils from "@packages/utils";
import globals from "@packages/components/globals";
import { loadLocaleMessages, setupI18n } from "@packages/i18n";
import mitt from "mitt";
import Notifications from "@kyvg/vue3-notification";
import "@packages/socket";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import Maska from "maska";
import { createSentry } from "@packages/sentry";
import {Router} from "vue-router";
import type { App } from "vue";

//@ToDo we may later add other parameters to this function for customization (e.g.: to customize which plugins to use)
/**
 * Executes the following statement: window.global = window;
 */

import { plugin as VueTippy } from 'vue-tippy'
import 'tippy.js/dist/tippy.css' // optional for styling

function createVueApp(App: Component, router: Router): App<Element> {
    window.global = window;

    const emitter = mitt();
    const i18n = setupI18n();
    loadLocaleMessages(i18n, "tr");

    const app = createApp(App);

    createSentry(app, router);

    app
        .use(router)
        .use(createPinia())
        .use(i18n)
        .use(VueSweetalert2)
        .use(Maska)
        .use(
            VueTippy,
            // optional
            {
                directive: 'tippy', // => v-tippy
                component: 'tippy', // => <tippy/>
                componentSingleton: 'tippy-singleton', // => <tippy-singleton/>,
                defaultProps: {
                    placement: 'auto-end',
                    allowHTML: true,
                }, // => Global default options * see all props
            }
        )
        .use(Notifications);

    app.config.globalProperties.emitter = emitter;
    app.provide("axios", app.config.globalProperties.axios);

    globals(app);
    utils(app);

    app.mount("#app");

    return app;
}

export {
    createVueApp,
}
