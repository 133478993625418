import {$t} from "@packages/i18n";
import {notify} from "@kyvg/vue3-notification";
import {AxiosInstance} from "axios";
import {useCompanyStore} from "@/stores/company";
import {useAuthStore} from "@/stores/auth";
import {RouteLocationRaw} from "vue-router";
import router from "@/router";
import {getRouteForMyAccount} from "./user-panel";

/**
 * Adds an interceptor to the response via the Axios.interceptors.response.use() method.
 */
export function interceptResponse(axios: AxiosInstance): AxiosInstance {
    axios.interceptors.response.use(
    function (response) {
            return response;
        },
    async function (error) {
        //the result that will be returned
        const _return = () => Promise.reject(error)

        //returns if there is not a response
        if(! error.response) {
            return _return()
        }

        //logs out and redirects to the login page if unauthenticated
        if (error.response.status === 401) {
            const authStore = useAuthStore();

            await authStore.logOutAndRedirectToLogIn();
        }
        //if the request was unauthorized
        else if (error.response.status === 403) {
            const errorCode = error.response.data?.data?.error_code

            //if the error code states that the user/ip is banned
            if(errorCode === 6) {
                await router.push(getBannedRoute())

                return _return()
            }

            //resets the selected company and redirects to the "My Account" page if the error code states that the user
            //is unauthorized to access the requested firm or branch. Then, redirects to the home page.
            if(errorCode === 2 || errorCode === 3) {
                useCompanyStore().setSelectedCompany(undefined)

                await router.push(getRouteForMyAccount())

                notify({
                    title: $t('Unauthorized'),
                    text: $t(
                        errorCode === 2 ? 'You are not authorized to access this firm'
                            : 'You are not authorized to access this branch'
                    )
                })

                return _return()
            }

            //notifies
            notify({
                title: $t('Unauthorized'),
                text: $t('You are not authorized to perform this action.')
            })
        }

        if(error.response.data?.notify && (error.response.status === 400 || error.response.status === 500)) {
            notify({
                title: $t('Operation-Failed-Title'),
                text: error.response.data?.failure?.messages.user_friendly.main
            })
        }

        return _return()
    })

    return axios
}

export function getLoginRoute(): RouteLocationRaw {
    return { name: "login" }
}

/**
 * The route that banned users should be redirected to.
 */
export function getBannedRoute(): RouteLocationRaw {
    return { name: "banned" }
}