import * as Sentry from "@sentry/vue";
import {browserTracingIntegration, replayIntegration} from "@sentry/vue";
import type {App} from "vue";
import type {Router} from "vue-router";

export const createSentry = (app: App, router: Router) => {
    Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_DNS,
        integrations: [
            browserTracingIntegration({router: router}),
            replayIntegration(),
        ],
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        enabled: process.env.NODE_ENV === "production",
    });
};
