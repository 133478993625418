<script lang="ts" setup>
import { onMounted, ref } from "vue";
import type { Notify } from "@packages/composables/useEmitter";
import useEmitter from "@packages/composables/useEmitter";

const nextId = ref(1)

function getNextId() {
  const id = nextId.value

  nextId.value++

  return id
}

const show = ref(false);
const { on } = useEmitter();
const notification = ref<(Notify & {id: number})|null>(null);

function getNotificationDefaults(): Partial<Notify> {
  return {
    position: "top-right",
    duration: 3_000,
  }
}

/**
 * Closes the notification
 */
function close() {
  show.value = false;

  notification.value = null;
}

/**
 * Closes the notification with the given id.
 * @param id
 */
function closeWhereId(id: number) {
  //does not proceed unless there is a notification and the parameter "id" matches the current notification's id
  if (notification.value === null || notification.value.id !== id) {
    return
  }

  close()
}

onMounted(() => {
  on("notify:received", async (message: Notify) => {
    const id = getNextId()

    notification.value = {...getNotificationDefaults(), ...message, ...{id: id}}

    show.value = true;

    setTimeout(() => closeWhereId(id), notification.value.duration);
  });
});
</script>

<template>
  <teleport to="body">
    <div
        aria-live="assertive"
        class="pointer-events-none fixed inset-0 flex px-4 py-6 sm:p-6"
        style="z-index:99;"
    >
      <div class="flex w-full flex-col space-y-4">
        <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
        <transition
            enter-active-class="transform ease-out duration-300 transition"
            enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
            leave-active-class="transition ease-in duration-100"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
        >
          <div
              v-if="notification?.id && show"
              class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg absolute"
              :class="{
                'top-8 left-8': notification.position === 'top-left',
                'top-8 inset-x-1/4 md:inset-x-1/2': notification.position === 'top-center',
                'top-8 right-8': notification.position === 'top-right',
              }"
          >
            <div class="p-4">
              <div class="flex items-start">
                <div class="ml-3 w-0 flex-1 py-0.5">
                  <p v-if="notification?.title" class="text-base font-medium text-gray-900">
                    {{ notification?.title }}
                  </p>
                  <p v-if="notification?.text" class="mt-1 text-sm text-gray-700">
                    {{ notification?.text }}
                  </p>
                </div>
                <div class="ml-4 flex flex-shrink-0">
                  <XIcon @click.prevent="close" class="ml-auto rounded-full hover:bg-gray-500/25"></XIcon>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </teleport>
</template>