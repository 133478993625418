import router from "@/router";
import {getUser, logOutUser} from "@/requests/requests/users-requests";
import {getLoginRoute} from "@/services/auth";
import {defineAuthStore} from "@packages/stores/auth";

const useAuthStore = defineAuthStore(
    logOutUser,
    async () => await router.push(getLoginRoute()),
    getUser,
)

export {
  useAuthStore,
}