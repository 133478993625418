import { useAuthStore } from "@/stores/auth";
import { useCompanyStore } from "@/stores/company";
import {Header} from "./types";

export const integrateHeaders = (...headers: Header[]): Header => {
    const obj: Header = {};

    headers.forEach( (header: Header) => {
        Object.assign(obj, header)
    })

    return obj
}

export const computeGeneralHeaders = (): Header => {
    return {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "X-Requested-With": "XMLHttpRequest",
    }
}

export const computeAuthenticationHeaders = (): Header => {
    const authStore = useAuthStore();

    if (! authStore.getToken) {
        return {};
    }

    return { Authorization: `Bearer ` + authStore.getToken };
}

export const computeMetaHeaders = (): Header => {
    const companyStore = useCompanyStore();

    //@ts-ignore
    if (! companyStore.getSelectedCompany?.code) {
        return {}
    }

    return {
        //@ts-ignore
        "X-Current-Branch": companyStore.getSelectedCompany.code,
    }
}

export const computeDefaultHeaders = (): Header => {
    return integrateHeaders(
        computeMetaHeaders(),
        computeAuthenticationHeaders(),
        computeGeneralHeaders(),
    )
}
