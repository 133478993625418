import {AxiosInstance, AxiosRequestHeaders} from "axios";
import {computeDefaultHeaders} from "./headers";
import {interceptResponse} from "@/services/auth";

export const useRequestInterceptors = (axios: AxiosInstance): AxiosInstance => {
    axios.interceptors.request.use((config) => {

        config.headers = computeDefaultHeaders() as unknown as AxiosRequestHeaders;

        return config;
    });

    return axios
}

export const useResponseInterceptors = interceptResponse

export const useInterceptors = (axios: AxiosInstance): AxiosInstance => useResponseInterceptors(useRequestInterceptors(axios))
