import axios, {AxiosInstance, AxiosRequestConfig, AxiosRequestHeaders} from "axios";
import { useAuthStore } from "./stores/auth";
import { useCompanyStore } from "./stores/company";
import {interceptResponse} from "./services/auth";

export const getDefaultHttpConfig = (v2: boolean = false): AxiosRequestConfig => {
  return {
    baseURL: v2 ? import.meta.env.VITE_API_BASE_V2 : import.meta.env.VITE_API_BASE,
    headers: {
      "Content-Type": "application/json",
    },
  }
}

const http: AxiosInstance = axios.create(getDefaultHttpConfig());

export const makeHttp = (config?: AxiosRequestConfig, v2: boolean = false) => {
  const axiosConfig = Object.assign(getDefaultHttpConfig(v2), config ?? {});

  return axios.create(axiosConfig);
}

export const useHttp = (v2: boolean = true) => {
  return axios.create(getDefaultHttpConfig(v2));
}

http.interceptors.request.use((config) => {
  const authStore = useAuthStore();
  const companyStore = useCompanyStore();

  let headers = {
    Accept: "application/json",
    "X-Requested-With": "XMLHttpRequest",
  };

  if (companyStore.getSelectedCompany) {
    headers = {
      ...headers,
      ...{
        //@ts-ignore
        "X-Current-Branch": companyStore.getSelectedCompany.code,
      },
    };
  }

  if (authStore.getToken) {
    headers = {
      ...headers,
      ...{ Authorization: `Bearer ` + authStore.getToken },
    };
  }

  config.headers = headers as unknown as AxiosRequestHeaders;

  return config;
});

interceptResponse(http)

export default http;
